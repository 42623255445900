import React from 'react'
import "./style.scss";
import { Col, Row } from 'antd';
import icon from "../../asset/images/icon_7.svg";

const data = [
    {
      id: 1,
      title: (
        "24L"
      ),
      text: "Momo Sold",
    },
    {
      id: 2,
      title: (
        "2.5L"
      ),
      text: "Customers Served",
    },
    {
      id: 3,
      title: (
        "12+"
      ),
      text: "Product Category",
    },
    {
      id: 4,
      title: (
       "35+"
      ),
      text: "Momo Cart",
    },
    {
      id: 5,
      title: (
       "03"
      ),
      text: "Cloud Kitchen",
    },
    {
      id: 6,
      title: (
        "800+"
      ),
      text: "Franchise Query",
    },
  ];


const Achievements = () => {
  return (
    <div className='achievements-section'>
      <div className="achievements-container">
        <div className="heading">
        Our Business 
            <br />
            Achievements So Far
            <br />
            <span>in last 18 months</span>
        </div>
        <div className="sub-text">
        The Momo Mafia, founded in March 2023 with a single cart in Sector 21, Gurgaon, has rapidly expanded over the past 18 months. Now operating 32 carts across 11 Indian cities and three cloud kitchens across Gurgaon and Delhi, the company has supported 31 franchise partners in generating side income. With over 2.5 lakh customers served and more than 25 lakh momos made, The Momo Mafia is now focusing on cloud kitchen expansion, aiming to bring more partners on board and extend its reach further.
        </div>
        <Row gutter={[32, 32]} className="content">
          {data.map((item) => (
            <Col
              key={item.id}
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={8} // Breakpoints for responsiveness
              className="content-item"
            >
             <div className="top_side">
                <span className='title'>
                {item.title}
                </span>
                <img src={icon} alt='icon' />
             </div>
             <div className="text">{item.text}</div>
            </Col>
          ))}
        </Row>
        <div className="city-box">
            <div className="left_side">
                11+
                <div className='text'>Presence in cities</div>
            </div>
            <div className="right_side">
                <div>
                    <span>Delhi(09)</span>
                    <span>Manesar(01)</span>
                    <span>Gurgaon(10)</span>
                    <span>Greater Noida(02)</span>
                </div>
                <div>
                    <span>jaipur(02)</span>
                    <span>Rohtak(01)</span>
                    <span>Bhiwani(01)</span>
                    <span>Faridabad(01)</span>
                </div>
                <div>
                    <span>Rewari(01)</span>
                    <span>Bulandshahr(03)</span>
                    <span>Sonipat(01)</span>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Achievements
