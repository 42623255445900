import React from 'react'
import CartFranchiseBanner from '../../component/cartFranchiseBanner/CartFranchiseBanner'
import AboutTheBrand from '../../component/AboutTheBrand/AboutTheBrand'
import OurOutlets from '../../component/OurOutlets/OurOutlets'
import BrandGrowth from '../../component/BrandGrowth/BrandGrowth'
import SatisfiedPartners from '../../component/SatisfiedPartners/SatisfiedPartners'
import FranchiseOverview from '../../component/FranchiseOverview/FranchiseOverview'
import CapitalInvestment from '../../component/CapitalInvestment/CapitalInvestment'
import ProductRange from '../../component/ProductRange/ProductRange'
import LocationSelection from '../../component/LocationSelection/LocationSelection'
import TrainingSupport from '../../component/trainingSupport/TrainingSupport'
import SocialMedia from '../../component/SocialMedia/SocialMedia'
import RowMaterialDelivery from '../../component/RowMaterialDelivery/RowMaterialDelivery'
import TechnologyInfrastructure from '../../component/TechnologyInfrastructure/TechnologyInfrastructure'
import LowRisk from '../../component/LowRisk/LowRisk'
import InstagramFeedElfsight from '../../commonComponent/social/InstagramFeedElfsight/InstagramFeedElfsight'
import FranchiseFeatures from '../../component/FranchiseFeatures/FranchiseFeatures'
import HowItWorks from '../../component/howItWorks/HowItWorks'

const CartFranchise = () => {
  return (
    <>
    <CartFranchiseBanner />
    <FranchiseFeatures />
    <FranchiseOverview />
    <HowItWorks />
    {/* <AboutTheBrand />
    <OurOutlets />
    <BrandGrowth />
    <SatisfiedPartners />
    <CapitalInvestment /> */}
    {/* <ProductRange /> */}
    {/* <LocationSelection />
    <TrainingSupport /> */}
    <SocialMedia />
    {/* <RowMaterialDelivery />
    <TechnologyInfrastructure /> */}
    <LowRisk />
    </>
  )
}

export default CartFranchise
